import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getDifferentKeys(obj1: any, obj2: any) {
  const allKeys = new Set([
    ...Object.keys(obj1 ?? {}),
    ...Object.keys(obj2 ?? {}),
  ]);
  const differentKeys = [];

  for (const key of allKeys) {
    if (obj1[key] !== obj2[key]) {
      differentKeys.push(key);
    }
  }

  return differentKeys;
}

export function generateFilenameWithDateTime(
  filename: string,
  extension: string
): string {
  const date = new Date();
  const dateString = date.toISOString().replace(/:/g, "-").slice(0, 19);
  return `${filename}_${dateString}.${extension}`;
}

export function getMonthName(index: number): string {
  const date = new Date();
  date.setMonth(index);
  return new Intl.DateTimeFormat("en-AU", { month: "long" }).format(date);
}
